jQuery( document ).ready(function($) {

  AOS.init({
    duration: 1000,
    once: true,
  })

  const allLinks = document.querySelectorAll('svg a')
  allLinks.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const link = $(e.target).parent().attr('data-popup-link')
      const message = $(e.target).parent().attr('data-popup-message')
      $('.popup').addClass('active')
      $('.popup h3').html(message)
      $('.popup a').attr('href', link)
    })
  })
  $('.popup .close').click(() => {
    $('.popup').removeClass('active')
  })

  const desktopBg = document.querySelector('svg.desktop image')
  const mobileBg = document.querySelector('svg.mobile image')

  function setBackground(number){
    desktopBg.setAttribute('xlink:href', `./dist/img/bg/desktop/bg-desktop-${number}.jpg`)
    mobileBg.setAttribute('xlink:href', `./dist/img/bg/mobile/bg-mobile-${number}.jpg`)
  }

  // setBackground(12)
  

})